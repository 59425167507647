var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("table-component", {
        attrs: {
          "items-repo": _vm.locationsRepo,
          "load-params": { PracticeId: _vm.$route.params.id },
          isDataFromModel: false,
          sidebarData: _vm.selectedLocation,
          fields: _vm.fields,
          isAddEditSidbar: false,
          copyData: _vm.selectedLocationCopy,
          "add-edit-link": {
            name: "practices-details-view-location",
            params: {
              itemId: _vm.selectedLocation.id ? _vm.selectedLocation.id : "new",
            },
          },
          "get-by-id-on-edit-filter": function () {
            return false
          },
          "delete-filter": function () {
            return false
          },
        },
        on: {
          "update:sidebarData": function ($event) {
            _vm.selectedLocation = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.selectedLocation = $event
          },
          "update:copyData": function ($event) {
            _vm.selectedLocationCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.selectedLocationCopy = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "cell(location)",
            fn: function (data) {
              return [
                _c("b", [_vm._v(_vm._s(data.item.name))]),
                _c("br"),
                _c("span", { staticClass: "grey-text smaller-text" }, [
                  _vm._v(
                    "\n              NPI: " +
                      _vm._s(data.item.id) +
                      " | POS:\n              " +
                      _vm._s(
                        data.item.placeOfService
                          ? data.item.placeOfService.code
                          : ""
                      ) +
                      " | SC:\n              " +
                      _vm._s(data.item.code) +
                      "\n            "
                  ),
                ]),
              ]
            },
          },
          {
            key: "cell(address)",
            fn: function (data) {
              return [
                data.item.address
                  ? _c("div", [
                      _vm._v(
                        "\n        " +
                          _vm._s(data.item.address.address1) +
                          "\n        " +
                          _vm._s(data.item.address.address2) +
                          "\n        "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n        " +
                          _vm._s(data.item.address.country) +
                          " " +
                          _vm._s(data.item.address.city) +
                          "\n        " +
                          _vm._s(data.item.address.state) +
                          " " +
                          _vm._s(data.item.address.zip) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(contacts)",
            fn: function (data) {
              return [
                _vm._l(data.item.locationContacts, function (contact, index) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          contact.practiceContact.firstName +
                            " " +
                            contact.practiceContact.lastName
                        ) +
                        "\n        "
                    ),
                    _vm._l(
                      contact.practiceContact.phones,
                      function (phone, index) {
                        return [
                          phone.type == "Work"
                            ? _c("feather-icon", {
                                staticClass: "grey-text",
                                attrs: { icon: "BriefcaseIcon" },
                              })
                            : _vm._e(),
                          phone.type == "Personal"
                            ? _c("feather-icon", {
                                staticClass: "grey-text",
                                attrs: { icon: "SmartphoneIcon" },
                              })
                            : _vm._e(),
                          phone.type == "Home"
                            ? _c("feather-icon", {
                                staticClass: "grey-text",
                                attrs: { icon: "HomeIcon" },
                              })
                            : _vm._e(),
                          _vm._v(
                            "\n          " + _vm._s(phone.number) + "\n        "
                          ),
                        ]
                      }
                    ),
                  ]
                }),
              ]
            },
          },
          {
            key: "cell(billPatient)",
            fn: function (data) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(data.item.billPatient ? "YES" : "NO") +
                    "\n    "
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }