




import PracticesDetailsCC from '/src/class-components/practices/PracticesDetailsCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    PracticesDetailsCC
  }
})
export default class PracticesDetails extends Vue {}
