var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("table-component", {
        attrs: {
          "items-repo": _vm.providersRepo,
          "load-params": { PracticeId: _vm.$route.params.id },
          isDataFromModel: false,
          sidebarData: _vm.selectedProvider,
          fields: _vm.fields,
          isAddEditSidbar: false,
          copyData: _vm.selectedProviderCopy,
          "add-edit-link": {
            name: "practices-details-view-provider",
            params: {
              itemData: _vm.selectedProvider.id
                ? _vm.selectedProvider.id
                : "new",
            },
          },
          "get-by-id-on-edit-filter": function () {
            return false
          },
        },
        on: {
          "update:sidebarData": function ($event) {
            _vm.selectedProvider = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.selectedProvider = $event
          },
          "update:copyData": function ($event) {
            _vm.selectedProviderCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.selectedProviderCopy = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "cell(fullName)",
            fn: function (data) {
              return [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "pr-0", attrs: { cols: "3" } },
                      [
                        _c("b-avatar", {
                          staticClass: "float-left",
                          attrs: {
                            variant: _vm.getColor(),
                            text: _vm.getName(
                              data.item.firstName + " " + data.item.lastName
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("b-col", { staticClass: "pl-0" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            data.item.firstName + " " + data.item.lastName
                          ) +
                          "\n          "
                      ),
                      _c("br"),
                      _c("span", { staticClass: "grey-text smaller-text" }, [
                        _vm._v(
                          "\n                  NPI: " +
                            _vm._s(data.item.npiNumber || "N/A") +
                            " | SC:\n                  " +
                            _vm._s(data.item.code || "N/A") +
                            "\n                "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }