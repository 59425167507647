var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-component", {
    attrs: { copyData: _vm.formDataCopy, validate: true },
    on: {
      "update:copyData": function ($event) {
        _vm.formDataCopy = $event
      },
      "update:copy-data": function ($event) {
        _vm.formDataCopy = $event
      },
      save: _vm.save,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { md: "4", sm: "6" } },
                  [
                    _c("h6", [_c("b", [_vm._v("In network")])]),
                    _c("br"),
                    _c("label", [_vm._v("Co-Pay")]),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "6", sm: "12" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Primary In network Co-Pay",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              options: _vm.patientBillingType,
                                              placeholder: "Primary",
                                              reduce: function (x) {
                                                return x.id
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.formDataCopy
                                                  .inPrmCoPayBillType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDataCopy,
                                                  "inPrmCoPayBillType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formDataCopy.inPrmCoPayBillType",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "6", sm: "12" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Secondary In network Co-Pay",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              options: _vm.patientBillingType,
                                              placeholder: "Secondary",
                                              reduce: function (x) {
                                                return x.id
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.formDataCopy
                                                  .inSecCoPayBillType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDataCopy,
                                                  "inSecCoPayBillType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formDataCopy.inSecCoPayBillType",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("label", [_vm._v("Co-Insurance")]),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "6", sm: "12" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Primary In network Co-Insurance",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              options: _vm.patientBillingType,
                                              placeholder: "Primary",
                                              reduce: function (x) {
                                                return x.id
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.formDataCopy
                                                  .inPrmCoInsBillType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDataCopy,
                                                  "inPrmCoInsBillType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formDataCopy.inPrmCoInsBillType",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "6", sm: "12" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Secondary In network Co-Insurance",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              options: _vm.patientBillingType,
                                              placeholder: "Secondary",
                                              reduce: function (x) {
                                                return x.id
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.formDataCopy
                                                  .inSecCoInsBillType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDataCopy,
                                                  "inSecCoInsBillType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formDataCopy.inSecCoInsBillType",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("label", [_vm._v("Deductible")]),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "6", sm: "12" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Primary In network Deductible",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              options: _vm.patientBillingType,
                                              placeholder: "Primary",
                                              reduce: function (x) {
                                                return x.id
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.formDataCopy
                                                  .inPrmDedBillType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDataCopy,
                                                  "inPrmDedBillType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formDataCopy.inPrmDedBillType",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "6", sm: "12" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Secondary In network Deductible",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              options: _vm.patientBillingType,
                                              placeholder: "Secondary",
                                              reduce: function (x) {
                                                return x.id
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.formDataCopy
                                                  .inSecDedBillType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDataCopy,
                                                  "inSecDedBillType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formDataCopy.inSecDedBillType",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "4", sm: "6" } },
                  [
                    _c("h6", [_c("b", [_vm._v("Out of network")])]),
                    _c("br"),
                    _c("label", [_vm._v("Co-Pay")]),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "6", sm: "12" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Primary Out of network Co-Pay",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              options: _vm.patientBillingType,
                                              placeholder: "Primary",
                                              reduce: function (x) {
                                                return x.id
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.formDataCopy
                                                  .outPrmCoPayBillType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDataCopy,
                                                  "outPrmCoPayBillType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formDataCopy.outPrmCoPayBillType",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "6", sm: "12" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Secondary Out of network Co-Pay",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              options: _vm.patientBillingType,
                                              placeholder: "Secondary",
                                              reduce: function (x) {
                                                return x.id
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.formDataCopy
                                                  .outSecCoPayBillType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDataCopy,
                                                  "outSecCoPayBillType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formDataCopy.outSecCoPayBillType",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("label", [_vm._v("Co-Insurance")]),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "6", sm: "12" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Primary Out of network Co-Insurance",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              options: _vm.patientBillingType,
                                              placeholder: "Primary",
                                              reduce: function (x) {
                                                return x.id
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.formDataCopy
                                                  .outPrmCoInsBillType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDataCopy,
                                                  "outPrmCoInsBillType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formDataCopy.outPrmCoInsBillType",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "6", sm: "12" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Secondary Out of network Co-Insurance",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              options: _vm.patientBillingType,
                                              placeholder: "Secondary",
                                              reduce: function (x) {
                                                return x.id
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.formDataCopy
                                                  .outSecCoInsBillType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDataCopy,
                                                  "outSecCoInsBillType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formDataCopy.outSecCoInsBillType",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("label", [_vm._v("Deductible")]),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "6", sm: "12" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Primary Out of network Deductible",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              options: _vm.patientBillingType,
                                              placeholder: "Primary",
                                              reduce: function (x) {
                                                return x.id
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.formDataCopy
                                                  .outPrmDedBillType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDataCopy,
                                                  "outPrmDedBillType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formDataCopy.outPrmDedBillType",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "6", sm: "12" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Secondary Out of network Deductible",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              options: _vm.patientBillingType,
                                              placeholder: "Secondary",
                                              reduce: function (x) {
                                                return x.id
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.formDataCopy
                                                  .outSecDedBillType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDataCopy,
                                                  "outSecDedBillType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formDataCopy.outSecDedBillType",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "4", sm: "6" } },
                  [
                    _c("h6", [_c("b", [_vm._v("Billing")])]),
                    _c("br"),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "6", sm: "12" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Min. Bill Amount",
                                  "label-for": "minimumBillAmount",
                                },
                              },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Min. Bill Amount",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "minimumBillAmount",
                                              type: "number",
                                            },
                                            model: {
                                              value:
                                                _vm.formDataCopy
                                                  .minimumBillAmount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDataCopy,
                                                  "minimumBillAmount",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "formDataCopy.minimumBillAmount",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "6", sm: "12" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Min. Statement Amount",
                                  "label-for": "mimimumStatementAmount",
                                },
                              },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Min. Statement Amount",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "mimimumStatementAmount",
                                              type: "number",
                                            },
                                            model: {
                                              value:
                                                _vm.formDataCopy
                                                  .mimimumStatementAmount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDataCopy,
                                                  "mimimumStatementAmount",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "formDataCopy.mimimumStatementAmount",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "12", sm: "12" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Bill After PR 100 (in days)",
                                  "label-for": "billDaysAfterPr100",
                                },
                              },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Bill After PR 100",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "billDaysAfterPr100",
                                              type: "number",
                                            },
                                            model: {
                                              value:
                                                _vm.formDataCopy
                                                  .billDaysAfterPr100,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDataCopy,
                                                  "billDaysAfterPr100",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "formDataCopy.billDaysAfterPr100",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "mt-2" },
              [
                _c("b-col", [
                  _vm._v("\n        Y = Should bill\n        "),
                  _c("br"),
                  _vm._v("\n        N = Should not bill\n        "),
                  _c("br"),
                  _vm._v(
                    "\n        P = Should only bill if we did not get any insurance payment for\n        this visit\n        "
                  ),
                  _c("br"),
                  _vm._v("\n        C = Confirm with Doctor\n      "),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.formData,
      callback: function ($$v) {
        _vm.formData = $$v
      },
      expression: "formData",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }