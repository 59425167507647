var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "validation-observer",
        { ref: "simpleRules" },
        [
          _c(
            "form-component",
            {
              attrs: {
                copyData: _vm.practiceCopy,
                validate: _vm.practiceCopy.status === "Active",
                "show-status": _vm.formData.id,
              },
              on: {
                "update:copyData": function ($event) {
                  _vm.practiceCopy = $event
                },
                "update:copy-data": function ($event) {
                  _vm.practiceCopy = $event
                },
                save: _vm.savePractice,
              },
              model: {
                value: _vm.formData,
                callback: function ($$v) {
                  _vm.formData = $$v
                },
                expression: "formData",
              },
            },
            [
              _c(
                "template",
                { slot: "body" },
                [
                  _c(
                    "b-form",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "4", sm: "6" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Practice Name",
                                    "label-for": "basicInput",
                                  },
                                },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Practice Name",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-input", {
                                              attrs: {
                                                id: "basicInput",
                                                placeholder: "Enter Name",
                                              },
                                              model: {
                                                value: _vm.practiceCopy.name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.practiceCopy,
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression: "practiceCopy.name",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "EHR System",
                                            "label-for": "basicInput",
                                          },
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "EHR System",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c("b-form-select", {
                                                      attrs: {
                                                        options: _vm.ehrOptions,
                                                        "text-field": "name",
                                                        "value-field": "id",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.practiceCopy
                                                            .ehrSystemId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.practiceCopy,
                                                            "ehrSystemId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "practiceCopy.ehrSystemId",
                                                      },
                                                    }),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "text-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Start Date",
                                            "label-for": "basicInput",
                                          },
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "Start Date",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c("b-form-datepicker", {
                                                      attrs: { type: "date" },
                                                      model: {
                                                        value:
                                                          _vm.practiceCopy
                                                            .dateStart,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.practiceCopy,
                                                            "dateStart",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "practiceCopy.dateStart",
                                                      },
                                                    }),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "text-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "End Date",
                                            "label-for": "basicInput",
                                          },
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "End Date",
                                              rules: "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c("b-form-datepicker", {
                                                      attrs: { type: "date" },
                                                      model: {
                                                        value:
                                                          _vm.practiceCopy
                                                            .dateEnd,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.practiceCopy,
                                                            "dateEnd",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "practiceCopy.dateEnd",
                                                      },
                                                    }),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "text-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Website",
                                    "label-for": "basicInput",
                                  },
                                },
                                [
                                  _c("validation-provider", {
                                    attrs: { name: "Website", rules: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-input", {
                                              attrs: {
                                                id: "basicInput",
                                                placeholder: "Enter Website",
                                              },
                                              model: {
                                                value: _vm.practiceCopy.website,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.practiceCopy,
                                                    "website",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "practiceCopy.website",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "4", sm: "6" } },
                            [
                              _c("address-component", {
                                model: {
                                  value: _vm.practiceCopy.address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.practiceCopy, "address", $$v)
                                  },
                                  expression: "practiceCopy.address",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "4", sm: "6" } },
                            [
                              _c("email-phone-single", {
                                attrs: {
                                  sourcePhone: _vm.practiceCopy.phone,
                                  sourceEmail: _vm.practiceCopy.email,
                                },
                                on: {
                                  "update:sourcePhone": function ($event) {
                                    return _vm.$set(
                                      _vm.practiceCopy,
                                      "phone",
                                      $event
                                    )
                                  },
                                  "update:source-phone": function ($event) {
                                    return _vm.$set(
                                      _vm.practiceCopy,
                                      "phone",
                                      $event
                                    )
                                  },
                                  "update:sourceEmail": function ($event) {
                                    return _vm.$set(
                                      _vm.practiceCopy,
                                      "email",
                                      $event
                                    )
                                  },
                                  "update:source-email": function ($event) {
                                    return _vm.$set(
                                      _vm.practiceCopy,
                                      "email",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }